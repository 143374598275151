<template>
  <v-container
    style="padding: 0 0 20px 0; margin: auto; border-radius: 5px; background:white;"
  >
    <v-card
      tile
      flat
      style="
          border-bottom: 2px solid rgba(0,0,0,0.1);
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          position:sticky;
          top:60px;
          background:white;
          z-index:5;
        "
    >
      <div
        style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
      >
        <v-toolbar-title class="overline">
          <p :style="`font-size:${wWidth > 780 ? '20px' : '3vw'}; margin: 0;`">
            Formulir Pengajuan Project Baru
          </p>
        </v-toolbar-title>
      </div>
      <div>
        <v-btn
          type="button"
          rounded
          outlined
          elevation="0"
          color="black"
          class="black--text font-weight-bold ma-2"
          style="font-size:12px;"
          @click="close"
        >
          <v-icon small>mdi-chevron-left</v-icon>
          Kembali
        </v-btn>
      </div>
    </v-card>

    <v-card elevation="0">
      <v-row style="padding: 0 15px;" no-gutters>
        <v-col cols="4" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Nama
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field
                disabled
                dense
                outlined
                :value="form.user_name"
                readonly
                style="height:45px;"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="4" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Perusahaan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-autocomplete
                disabled
                :items="dropdown.plant || []"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height:45px;"
                :value="form.user_company_id"
                readonly
              ></v-autocomplete>
            </v-col>
          </div>
        </v-col>
        <v-col cols="4" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Department/ Section
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-autocomplete
                disabled
                :items="dropdown.department"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height:45px;"
                :value="form.user_department_id"
                readonly
              ></v-autocomplete>
            </v-col>
          </div>
        </v-col>
      </v-row>
      <!-- <v-divider style="width:95%; margin:10px auto;" /> -->

      <v-form
        ref="entryForm"
        @submit.prevent="submit()"
        lazy-validation
        :disabled="loading"
        style="margin-top:15px;"
      >
        <v-row style="padding: 15px" no-gutters>
          <v-col cols="6" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Nama Project *
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-text-field
                  v-model="form.project_name"
                  :rules="rules.nameRules"
                  dense
                  outlined
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="6" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Kode Project *
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-text-field
                  v-model="form.project_code"
                  :rules="rules.codeRules"
                  dense
                  outlined
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="6" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Unit/Plant *
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-autocomplete
                  v-model="form.company"
                  :rules="rules.companyRules"
                  :items="dropdown.plant || []"
                  item-text="name"
                  item-value="id"
                  return-object
                  outlined
                  dense
                  style="margin: 0"
                  clearable
                ></v-autocomplete>
              </v-col>
            </div>
          </v-col>
          <v-col cols="6" style="padding: 0; margin-bottom:25px;">
            <div style="padding: 0 10px;">
              <v-col cols="12" style="padding: 0;">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Penangung Jawab *
                </p>
              </v-col>
              <v-col
                cols="12"
                style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:0;"
              >
                <v-autocomplete
                  v-model="form.pic"
                  :items="dropdown.employee"
                  :search-input.sync="search"
                  :rules="rules.picRules"
                  label="Ketik nama"
                  item-text="name"
                  item-id="id"
                  return-object
                  dense
                  clearable
                  deletable-chips
                  small-chips
                  outlined
                  hide-details
                  hide-no-data
                ></v-autocomplete>
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Keterangan Tambahan
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-textarea
                  dense
                  auto-grow
                  outlined
                  v-model="form.description"
                />
              </v-col>
            </div>
          </v-col>
        </v-row>
        <div
          class="d-flex  flex-column justify-center align-center"
          style="padding: 0 25px;"
        >
          <v-btn
            elevation="1"
            color="primary"
            type="submit"
            class="white--text font-weight-bold mb-5"
            style="font-size:14px; width:100%"
            :loading="loading"
          >
            Simpan
          </v-btn>
          <v-btn
            type="button"
            elevation="1"
            color="error"
            @click="clearForm()"
            class="white--text font-weight-bold"
            style="font-size:14px; width:100%"
            :loading="loading"
          >
            Hapus
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
// import moment from 'moment'
// var momentBusinessDays = require('moment-business-days')
export default {
  name: 'projectsubmission',
  components: {},
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      proc: buildType.apiURL('proc'),
      loading: false,
      search: null,
      select: null,
      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      form: {
        act: 'add',
        user_name: '',
        user_company_id: null,
        user_department_id: null,
        pic: null,
        company: null,
        project_name: '',
        project_code: '',
        code: '',
        description: ''
      },
      wWidth: window.innerWidth,
      rules: {
        nameRules: [],
        codeRules: [],
        companyRules: [],
        picRules: []
      },
      dropdown: {
        department: [],
        plant: [],
        employee: []
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getProjectDetail'])
  },
  async mounted() {
    this.form.user_name = this.getUserProfile.employee.name
    this.form.user_company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    this.form.user_department_id = this.getUserProfile.employee.department_id
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    await this.initDropdown()
    if (this.getProjectDetail !== null) {
      this.setDetailToForm()
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.$refs.entryForm.reset()
  },
  watch: {
    search(val) {
      if (val !== '') {
        val && val !== this.select && this.querySelections(val)
      } else {
        this.select = null
        this.arrEmployee = []
      }
    },
    'form.pic'() {
      if (this.form.pic === null) {
        this.search = null
        this.select = null
        this.arrEmployee = []
      }
    }
  },
  methods: {
    ...mapMutations(['setProjectDetail']),
    async initDropdown() {
      await this.dropdownPlant()
      await this.dropdownDepartment()
    },
    async setDetailToForm() {
      this.form.act = 'update'
      this.form.id = this.getProjectDetail.id
      this.form.project_code = this.getProjectDetail.odoo_project_code
      this.form.project_name = this.getProjectDetail.name
      this.form.code = this.getProjectDetail.code
      this.form.description = this.getProjectDetail.description
      this.form.company = {
        id: this.getProjectDetail.company_id,
        name: this.getProjectDetail.company_name
      }
      this.form.pic = {
        id: this.getProjectDetail.employee_id,
        name: this.getProjectDetail.employee_name
        // nik: this.getProjectDetail.employee_nik
      }
      this.querySelections(this.getProjectDetail.employee_name)
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    querySelections(v) {
      axios
        .get(`${this.hrsApi}employee/dropdown?keyword=${v}`)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.employee = res.data.data)
          }
          return (this.dropdown.employee = [])
        })
        .catch(err => {
          this.dropdown.employee = []
          return console.log(err)
        })
    },
    clearForm(val) {
      this.$refs.entryForm.reset()

      this.rules.descriptionRules = []
      this.rules.typeRules = []
      this.rules.categoryRules = []
      this.rules.totalRequestedRules = []
      this.rules.delegationRules = []
      this.rules.photoRules = []
      this.setProjectDetail(null)
    },
    submit() {
      console.log(this.form)
      const self = this
      this.rules.nameRules = [v => !!v || 'Nama project diperlukan']
      this.rules.codeRules = [v => !!v || 'Kode project diperlukan']
      this.rules.companyRules = [v => !!v || 'Unit/plant diperlukan']
      this.rules.picRules = [v => !!v || 'PIC diperlukan']

      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        name: this.form.project_name,
        code: this.form.code,
        odoo_project_code: this.form.project_code,
        company_id: this.form.company.id,
        company_name: this.form.company.name,
        employee_id: this.form.pic.id,
        employee_name: this.form.pic.name,
        employee_nik: this.form.pic.nik,
        description: this.form.description
      }
      if (form.act === 'update') {
        Object.assign(form, {
          id: this.form.id
        })
      }
      console.log(this.getUserProfile)
      console.log(form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.proc}project/save`, form)
        .then(res => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.clearForm()
            this.showMsgDialog('success', res.data.status_msg, false).then(
              response => {
                this.setProjectDetail(null)
                setTimeout(() => {
                  this.$router.push(
                    `/e-catalogue/project/detail/${
                      this.form.act === 'add'
                        ? res.data.created_id
                        : this.form.id
                    }`
                  )
                }, 500)
              }
            )
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    close() {
      this.$refs.entryForm.reset()
      this.setProjectDetail(null)
      if (this.prevRoute !== undefined) {
        this.$router.push(this.prevRoute.path)
      } else {
        this.$router.push('/hr/leave')
      }
    },
    async dropdownPlant() {
      await axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.plant = res.data.data)
          }
          return (this.dropdown.plant = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.plant = [])
        })
    },
    async dropdownDepartment() {
      await axios
        .get(`${this.hrsApi}master/universal/department/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },
    disableItem(item) {
      if (this.getUserProfile.employee.join_year_num >= 1) {
        return item.id === 5
      } else {
        if (item.id < 5) {
          if (item.id === 3) {
            return false
          }
          return true
        } else {
          return false
        }
      }
    }
  }
}
</script>
